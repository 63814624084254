import React from 'react';
import { Element } from 'react-scroll';

const MembershipCareerLaunchOffering = () => {
  return (
    <>
      <Element className="ms-offerings-section datatalent-section-gap">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-12 m-0">
              <h4 className="ms-offerings-blockquote">“Graduates from data science programs with internship experience with 94% securing full-time positions and a median salary of $105,000.”</h4>
              <p className="ms-offerings-description mt-5"><strong>CareerLaunch</strong> helps you break into tech and the industry with previous technical experience helping set you apart from other competitors. Also learn what you like to do in tech through the internship, making it a valuable learning experience.</p>
              <p className="ms-offerings-description mt-5">Experience what it is to actually live and work in the tech industry and in data. Fill in any gaps in knowledge through the courses continuously provided throughout your annual subscription.</p>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default MembershipCareerLaunchOffering;