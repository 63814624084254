import React from 'react'
import { Element } from 'react-scroll';
import AMSectionTitle from '../AMSectionTitle';
import { Link } from 'react-router-dom';

const MembershipAIReadyAbout = () => {
  const skillsboostBenefits = [
    {
      image: "courses.svg",
      title: "Access to Online Courses",
      description: "24/7 access to courses in Data Science, Machine Learning, and AI."
    },
    {
      image: "certificate.svg",
      title: "Industry-Recognized Certification",
      description: "Earn a certification to enhance your resume and show your skills."
    },
    {
      image: "ai_resume_builder.svg",
      title: "AI Resume Builder",
      description: "Access exclusive job listings in Data Science, Machine Learning, and AI."
    },
    {
      image: "workshops.svg",
      title: "Webinars and Workshops",
      description: "Join live webinars and workshops to build essential career skills."
    },
    {
      image: "community.svg",
      title: "Community Access",
      description: "Connect with peers and mentors in an active online community."
    }
  ]
  
  const aireadyBenefits = [
    {
      image: "advanced_courses.svg",
      title: "Advanced Courses",
      description: "Specializations in deep learning, NLP, and data analytics."
    },
    {
      image: "work_integrated.svg",
      title: "Work Integrated Projects",
      description: "Real-world, employer-led projects with companies in diverse industries."
    },
    {
      image: "mentorship.svg",
      title: "Mentorship",
      description: "Monthly one-on-one guidance from industry experts."
    },
    {
      image: "job_search.svg",
      title: "Job Placement Assistance",
      description: "Exclusive job listings and portfolio building support."
    }
  ]

  return (
    <>
      <Element className="edu-about-area bg-white datatalent-section-gap">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 mt-0 p-0">
              <AMSectionTitle
                classes = "text-left"
                // slogan = "Introduction"
                title = "What's included in AIReady?"
              />
            </div>
          </div>
          <div className="row g-5">
            <div className="col-12 p-0">
              <p className="ms-about-description">
                The AI Ready program advances your learning with specialized courses in deep learning, natural language processing, and data analytics. Continue to build your toolkit through online self-paced courses and use what you learned in your paid work integrated learning project. 
              </p>
              <p className="ms-about-description">
                Gain real-world experience through employer-led projects and receive personalized mentorship to thrive in today's job market. 
              </p>
              <p className="ms-about-description-highlight">
                <strong>AI Ready expands your project portfolio through paid data science projects with our WIL (work integrated learning) projects to gain real-world experience.</strong>
              </p>
            </div>
            <div className="col-12">
              <div className="aiready-benefits-container d-none d-md-flex flex-row align-items-center">
                <div className="col-lg-4 d-flex flex-wrap justify-content-end">
                  <div className="skillsboost-benefits-card">
                    <p className="skillsboost-benefits-card-header my-2">All Benefits of SkillsBoost</p>
                    <ul className="mb-0">
                      {skillsboostBenefits.map((skillsboostBenefit, idx) => (<li className="mb-0" key={idx}>{skillsboostBenefit.title}</li>))}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-1 text-center">
                  <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/plus_icon.svg`} alt="illustration" />
                </div>
                <div className="col-lg-7 aiready-benefits-card-container d-flex flex-row flex-wrap justify-content-center">
                  {aireadyBenefits.map((aireadyBenefit, idx) => (<div className="col-lg-6" key={idx}>
                    <div className="skillsboost-benefits-card">
                      <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/${ aireadyBenefit.image }`} alt="illustration" />
                      <p className="skillsboost-benefits-card-header my-2">{aireadyBenefit.title}</p>
                      <p className="mb-0">{aireadyBenefit.description}</p>
                    </div>
                  </div>))}
                </div>
              </div>
              <div className="col-12 d-flex d-md-none flex-wrap p-0 m-0 justify-content-center">
                <div className="skillsboost-benefits-card">
                  <p className="skillsboost-benefits-card-header my-2">All Benefits of SkillsBoost</p>
                  <ul className="mb-0">
                    {skillsboostBenefits.map((skillsboostBenefit, idx) => (<li className="mb-0" key={idx}>{skillsboostBenefit.title}</li>))}
                  </ul>
                </div>
              </div>
              <div className="col-12 d-md-none text-center">
                <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/plus_icon.svg`} alt="illustration" />
              </div>
              <div className="col-12 d-md-none aiready-benefits-card-container d-flex flex-row flex-wrap justify-content-center">
                {aireadyBenefits.map((aireadyBenefit, idx) => (<div className="col-12 d-flex justify-content-center" key={idx}>
                  <div className="skillsboost-benefits-card">
                    <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/${ aireadyBenefit.image }`} alt="illustration" />
                    <p className="skillsboost-benefits-card-header my-2">{aireadyBenefit.title}</p>
                    <p className="mb-0">{aireadyBenefit.description}</p>
                  </div>
                </div>))}
              </div>
            </div>
            <div className="col-12 mt--80">
              <div className="row g-5 position-relative ba-footer">
                <div className="col-lg-7 p-0 mt-0">
                  <h3 className="color-white">Ready to take the next step in<br></br>your career and become a<br></br>Data Science Expert?</h3>
                </div>
                <div className="col-lg-2 d-none d-md-block"></div>
                <div className="col-lg-3 p-0 justify-content-center align-items-center d-none d-md-flex">
                  <a className="edu-btn datatalent-cta" href="https://courses.m2mtechconnect.com/enroll/3086000">Register Now</a>
                </div>
                <p className="mb-0 p-0 d-block d-md-none">Join M2Ms Business Accelerator to unlock the potential of AI<br></br> and drive impactful change in your industry.</p>
                <Link to="/contact-us" className="d-flex d-md-none gap-2 mt-3 p-0"><span className="highlight-text-primary"><em>Chat with one of our experts to learn more</em></span> <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/maki_arrow.svg`} alt="right arrow" /></Link>
                <div className="col-lg-3 p-0 justify-content-center align-items-center d-flex d-md-none">
                  <a className="edu-btn datatalent-cta" href="/contact-us">Apply Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default MembershipAIReadyAbout;