import React from 'react'
import { Element } from 'react-scroll';
import AMSectionTitle from '../AMSectionTitle';
import { Link } from 'react-router-dom';

const MembershipCareerLaunchAbout = () => {
  const skillsboostBenefits = [
    {
      image: "courses.svg",
      title: "Access to Online Courses",
      description: "24/7 access to courses in Data Science, Machine Learning, and AI."
    },
    {
      image: "certificate.svg",
      title: "Industry-Recognized Certification",
      description: "Earn a certification to enhance your resume and show your skills."
    },
    {
      image: "ai_resume_builder.svg",
      title: "AI Resume Builder",
      description: "Access exclusive job listings in Data Science, Machine Learning, and AI."
    },
    {
      image: "workshops.svg",
      title: "Webinars and Workshops",
      description: "Join live webinars and workshops to build essential career skills."
    },
    {
      image: "community.svg",
      title: "Community Access",
      description: "Connect with peers and mentors in an active online community."
    }
  ]
  
  const aireadyBenefits = [
    {
      image: "advanced_courses.svg",
      title: "Advanced Courses",
      description: "Specializations in deep learning, NLP, and data analytics."
    },
    {
      image: "work_integrated.svg",
      title: "Work Integrated Projects",
      description: "Real-world, employer-led projects with companies in diverse industries."
    },
    {
      image: "mentorship.svg",
      title: "Mentorship",
      description: "Monthly one-on-one guidance from industry experts."
    },
    {
      image: "job_search.svg",
      title: "Job Placement Assistance",
      description: "Exclusive job listings and portfolio building support."
    }
  ]
  
  const careerLaunchBenefits = [
    {
      image: "placement.svg",
      title: "Guaranteed Internship Placement",
      description: "Hands-on experience with support throughout."
    },
    {
      image: "personalized_career_coaching.svg",
      title: "Personalized Career Coaching",
      description: "Bi-weekly sessions to guide career planning and goal setting."
    },
    {
      image: "networking.svg",
      title: "Premium Networking Opportunities",
      description: "Exclusive access to industry events and connections with leaders."
    },
    {
      image: "extended.svg",
      title: "Extended Access & Updates",
      description: "Lifetime access to new course materials and ongoing support."
    }
  ]

  return (
    <>
      <Element className="edu-about-area bg-white datatalent-section-gap">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 mt-0 p-0">
              <AMSectionTitle
                classes = "text-left"
                // slogan = "Introduction"
                title = "What's included in CareerLaunch?"
              />
            </div>
          </div>
          <div className="row g-5">
            <div className="col-12 p-0">
              <p className="ms-about-description">
                Take control of your future with CareerLaunch. Through guaranteed internships, personalized coaching, and exclusive networking opportunities, you'll gain the experience, skills, and connections to accelerate your career to the next level.
              </p>
              <p className="ms-about-description">
                Receive all the benefits from AI Ready and Skillsboost.
              </p>
              <p className="ms-about-description-highlight">
                <strong>Gain hands-on experience through a guaranteed paid data science internship.</strong>
              </p>
            </div>
            <div className="col-12">
              <div className="aiready-benefits-container d-none d-md-flex flex-row align-items-center">
                <div className="col-lg-4 d-flex flex-wrap justify-content-end">
                  <div className="skillsboost-benefits-card">
                    <p className="skillsboost-benefits-card-header my-2">All Benefits of AIReady</p>
                    <ul className="mb-0">
                      {skillsboostBenefits.concat(aireadyBenefits).map((skillsboostBenefit, idx) => (<li className="mb-0" key={idx}>{skillsboostBenefit.title}</li>))}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-1 text-center">
                  <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/plus_icon.svg`} alt="illustration" />
                </div>
                <div className="col-lg-7 aiready-benefits-card-container d-flex flex-row flex-wrap justify-content-center">
                  {careerLaunchBenefits.map((careerLaunchBenefit, idx) => (<div className="col-lg-6" key={idx}>
                    <div className="skillsboost-benefits-card">
                      <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/${ careerLaunchBenefit.image }`} alt="illustration" />
                      <p className="skillsboost-benefits-card-header my-2">{careerLaunchBenefit.title}</p>
                      <p className="mb-0">{careerLaunchBenefit.description}</p>
                    </div>
                  </div>))}
                </div>
              </div>
              <div className="col-12 d-flex d-md-none flex-wrap p-0 m-0 justify-content-center">
                <div className="skillsboost-benefits-card">
                  <p className="skillsboost-benefits-card-header my-2">All Benefits of AIReady</p>
                  <ul className="mb-0">
                    {skillsboostBenefits.concat(aireadyBenefits).map((skillsboostBenefit, idx) => (<li className="mb-0" key={idx}>{skillsboostBenefit.title}</li>))}
                  </ul>
                </div>
              </div>
              <div className="col-12 d-md-none text-center">
                <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/plus_icon.svg`} alt="illustration" />
              </div>
              <div className="col-12 d-md-none aiready-benefits-card-container d-flex flex-row flex-wrap justify-content-center">
                {careerLaunchBenefits.map((careerLaunchBenefit, idx) => (<div className="col-12 d-flex justify-content-center" key={idx}>
                  <div className="skillsboost-benefits-card">
                    <img src={`${process.env.PUBLIC_URL}/images/annual-membership/icons/${ careerLaunchBenefit.image }`} alt="illustration" />
                    <p className="skillsboost-benefits-card-header my-2">{careerLaunchBenefit.title}</p>
                    <p className="mb-0">{careerLaunchBenefit.description}</p>
                  </div>
                </div>))}
              </div>
            </div>
            <div className="col-12 mt--80">
              <div className="row g-5 position-relative ba-footer">
                <div className="col-lg-7 p-0 mt-0">
                  <h3 className="color-white">Ready to take the next step in<br></br>your career and become a<br></br>Data Science Expert?</h3>
                </div>
                <div className="col-lg-2 d-none d-md-block"></div>
                <div className="col-lg-3 p-0 justify-content-center align-items-center d-none d-md-flex">
                  <a className="edu-btn datatalent-cta" href="https://courses.m2mtechconnect.com/enroll/3086016">Register Now</a>
                </div>
                <p className="mb-0 p-0 d-block d-md-none">Join M2Ms Business Accelerator to unlock the potential of AI<br></br> and drive impactful change in your industry.</p>
                <Link to="/contact-us" className="d-flex d-md-none gap-2 mt-3 p-0"><span className="highlight-text-primary"><em>Chat with one of our experts to learn more</em></span> <img src={`${process.env.PUBLIC_URL}/images/business-accelerator/icons/maki_arrow.svg`} alt="right arrow" /></Link>
                <div className="col-lg-3 p-0 justify-content-center align-items-center d-flex d-md-none">
                  <a className="edu-btn datatalent-cta" href="/contact-us">Apply Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default MembershipCareerLaunchAbout;