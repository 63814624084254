import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import BannerOne from './BannerOne';
import LatestPostOne from './LatestPostOne';

const Blog1 = () => {
  return (
    <>
      <SEO title="Breaking Into ML Industry as a Student" />
      <Layout>
        <BreadcrumbOne 
          title="Breaking Into ML Industry as a Student"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Breaking Into ML Industry as a Student"
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-8">
                <div className="blog-details-1 style-variation2">
                  <div className="content-blog-top">
                    <div className="thumbnail">
                      <img className="radius-small w-100 mb--30" src={`${process.env.PUBLIC_URL}/images1/blog/blog-details-01/post-01.jpg`} alt="Blog Thumb" />
                    </div>
                    <ul className="blog-meta">
                      <li className="blog-author"><i className="icon-user-line"></i> Bao Nguyen</li>
                      <li><i className="icon-calendar-2-line"></i>Jan 24</li>
                      <li><i className="icon-time-line"></i>3 min read</li>
                    </ul>
                    <h4 className="title">Breaking Into ML Industry as a Student</h4>
                  </div>

                  <div className="blog-main-content">
                    <p>Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit. Vesti at bulum nec the odio aea the dumm ipsumm ipsum that dolocons rsus mal suada and fadolorit to the consectetur elit. All the Lorem Ipsum generators on the Internet tend to repeat that predefined chunks as necessary making this the first true dummy generator on the Internet.Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit. Vesti at bulum nec odio aea the dumm ipsumm ipsum that dolocons rsus mal suada and fadolorit to the consectetur elit.</p>

                    <p>Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit. Vesti at bulum nec at odio aea the dumm ipsumm ipsum that dolocons rsus mal suada and fadolorit to the consectetur elit. All the Lorem Ipsum generators on the Internet tend.</p>

                    <blockquote className="blockquote-style-1">“ Duis vel scelerisque augue, ut vehicula nisl. Curabitur et mollis tortor, eget sollicitudin lectus. Praesent a ante ac urna ultrices volutpat at sed ligula. Phasellus nibh dui, pulvinar a nibh quis. ”
                      <img className="quote-image" src="/images/icons/quote-2.png" alt="Quote Images" />
                    </blockquote>

                    <p>Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit. Vesti at bulum nec the odio aea the dumm ipsumm ipsum that dolocons rsus mal suada and fadolorit to the consectetur elit. All the Lorem Ipsum generators on the Internet tend to repeat that predefined chunks as necessary making this the first true dummy generator on the Internet.Grursus mal suada faci lisis Lorem ipsum dolarorit more ametion consectetur elit.</p>
                    <h5>Learned From Doing One Of Those Social Media</h5>
                    <ul className="list-style-1">
                      <li><i className="icon-checkbox-circle-fill-solid"></i> Struggling to sell one multi-million dollar home currently on the market</li>
                      <li><i className="icon-checkbox-circle-fill-solid"></i> The point of using Lorem Ipsum is that it has a normal distribution of letters.</li>
                      <li><i className="icon-checkbox-circle-fill-solid"></i> Publishing packages and web page editors now use their default model text.</li>
                      <li><i className="icon-checkbox-circle-fill-solid"></i> Publishing packages and web page editors now use their default model text.</li>
                    </ul>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <a href="#">
                            <i className="ri-arrow-left-s-line"></i>
                            <span>Social Media & evolution of visual design</span>
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <a href="#">
                            <span>Many important brands have given us their trust</span>
                            <i className="ri-arrow-right-s-line"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <aside className="edu-blog-sidebar">
                  {/* <SearchOne style2="enable" /> */}
                  {/* <CategoryOne extraClass="mt--50" style2="enable" /> */}
                  <LatestPostOne extraClass="mt--50" style2="enable" />
                  <BannerOne extraClass="mt--50" />
                  {/* <TagOne extraClass="mt--50" style2="enable" /> */}
                </aside>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Blog1;