import React from 'react';
import { Element } from 'react-scroll';
import AMSectionTitle from '../AMSectionTitle';
import Slider from 'react-slick';

const logos = [
  {
    image: "Andersen.jpeg"
  },
  {
    image: "bgis_logo.jpeg"
  },
  {
    image: "bpp_professional_education_logo.jpeg"
  },
  {
    image: "CAE.jpeg"
  },
  {
    image: "canadian_food_inspection_agency_logo.jpeg"
  },
  {
    image: "Dashio.jpeg"
  },
  {
    image: "government_of_saskatchewan_logo.jpeg"
  },
  {
    image: "healthcare_systems_r_a_logo.jpeg"
  },
  {
    image: "logo3.jpeg"
  },
  {
    image: "logo4.jpeg"
  },
  {
    image: "Oxford.jpeg"
  },
  {
    image: "QCA.jpeg"
  },
  {
    image: "Teck.jpeg"
  },
  {
    image: "Telus.jpeg"
  }
]

const MembershipSkillsboostWhereTo = () => {

  const classes = `testimonial-activation edu-slick-button slick-button-left`;

  const logoSliderMobile = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 2500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 300,
  };

  const logoSlider = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 2500,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 300,
  };
  
  return (
    <>
      <Element className="ms-where-to-section datatalent-section-gap home-one-cat">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 mt-0 p-0">
              <AMSectionTitle
                classes = "text-left"
                // slogan = "Introduction"
                title = "Where can this lead?"
              />
            </div>
            <div className="col-12">
              <p className="ms-where-to-description">Using the skills and experience gained in our programs, our past participants have gone on to work at leading companies.</p>
            </div>
            <div className="col-12 col-lg-6 logo-slider d-block d-md-none">
              <Slider
                className={ classes }
                { ...logoSliderMobile }
              >
                {logos.map( ( item, id ) => (
                  <div className="single-testimonial" key={ id }>
                    <div className="inner">
                      <img width={100} src={`${process.env.PUBLIC_URL}/images/data-talent/logos/${item.image}`} alt="logo" />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="col-12 logo-slider d-none d-md-block">
              <Slider
                className={ classes }
                { ...logoSlider }
              >
                {logos.map( ( item, id ) => (
                  <div className="single-testimonial" key={ id }>
                    <div className="inner">
                      <img width={100} src={`${process.env.PUBLIC_URL}/images/data-talent/logos/${item.image}`} alt="logo" />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default MembershipSkillsboostWhereTo;