import React from 'react'
import SEO from '../../../common/SEO';
import HeaderTwo from '../../../common/header/HeaderTwo';
import FooterOne from '../../../common/footer/FooterOne';
import MembershipSkillsboostBanner from '../../../components/annual-membership/membership-skillsboost/MembershipSkillsboostBanner';
import MembershipSkillsboostGlance from '../../../components/annual-membership/membership-skillsboost/MembershipSkillsboostGlance';
import MembershipSkillsboostOffering from '../../../components/annual-membership/membership-skillsboost/MembershipSkillsboostOffering';
import MembershipSkillsboostAbout from '../../../components/annual-membership/membership-skillsboost/MembershipSkillsboostAbout';
import MembershipSkillsboostWhereTo from '../../../components/annual-membership/membership-skillsboost/MembershipSkillsboostWhereTo';
import MembershipSkillsboostFaq from '../../../components/annual-membership/membership-skillsboost/MembershipSkillsboostFaq';

const MembershipSkillsboost = () => {

  return (
    <>
      <SEO
				title="Membership Skillsboost plan"
				description="The SkillsBoost program provides you with the fundamental knowledge and tools to kickstart your tech career. Designed for learners seeking flexibility, this self-paced program allows you to gain in-demand skills, earn industry-recognized certification, and connect with experts."
      />
      <HeaderTwo styles="header-transparent header-style-2" searchDisable />
      <MembershipSkillsboostBanner />
      <MembershipSkillsboostGlance />
      <MembershipSkillsboostOffering />
      <MembershipSkillsboostAbout />
      <MembershipSkillsboostWhereTo />
      <MembershipSkillsboostFaq />
      <FooterOne />
    </>
  )
}

export default MembershipSkillsboost;