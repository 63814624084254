import React from 'react';
import { Element } from 'react-scroll';

const MembershipSkillsboostOffering = () => {
  return (
    <>
      <Element className="ms-offerings-section datatalent-section-gap">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-12 m-0">
              <h4 className="ms-offerings-blockquote">“A study on workers who have industry level certification saw that they received pay increases between 20% - 40%.”</h4>
              <p className="ms-offerings-description mt-5"><strong>SkillsBoost</strong> offers continuous access to the latest resources and certifications from globally recognized platforms like <strong>Microsoft</strong>, <strong>AWS</strong>, and <strong>Google</strong>.</p>
              <p className="ms-offerings-description mt-5">With certifications such as <strong>Azure AI</strong>, <strong>AWS Machine Learning</strong>, and <strong>Google Cloud's Data Analytics tools</strong>, you'll gain the industry-relevant skills and knowledge that employers are looking for.</p>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default MembershipSkillsboostOffering;