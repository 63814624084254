import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import { Link } from 'react-router-dom';

const EventData = [
  {
    "id": 1,
    "title": "M2M Tech Product",
    "image": "health_sciences_data_analytics.webp",
    "website": "https://www.m2mtechconnect.com",
    "industry": "Manufacturing, Technology, Hospital, health, wellness & medical",
    "rating": "4.8 Google Rating",
    "duration": "12 Weeks",
    "category": "AI",
    "location": "https://www.m2mtechconnect.com",
    "going": "140+",
    "company": "M2M Tech"
  }
]

const M2MProduct = ({
  company
  }) => {
  const eventItem = EventData[0];

  return (
    <>
      <SEO
      title={ eventItem.title }
      description=""
      />
      <Layout>
        <BreadcrumbOne 
          title="M2M Tech Product"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="M2M Tech Product"
        />
        <div className="edu-event-details-area edu-event-details edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="thumbnail">
                  {/* <img src={`${process.env.PUBLIC_URL}/images/project-details/${ eventItem.image }`} alt="Event Thumb" /> */}
                  <img src="https://fakeimg.pl/1155x619?text=Product+Image+1&font=bebas" alt="placeholder" />
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="content">
                  <h3 className="title">{ eventItem.title }</h3>
                  <p>
                    <strong>What is it?</strong><br></br>
                    An independent machine that can train AI Models on its own with any connection to the internet.<br></br>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi modi sapiente saepe? Cum delectus alias fugit doloribus animi quos culpa repellendus temporibus eveniet non blanditiis incidunt, iusto veniam dolores consequatur!
                  </p>
                  <p>
                    <strong>How does it work?</strong><br></br>
                    It uses the OpenSource DeepSeek AI library.<br></br>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem ex velit est accusamus officia, dolorum doloribus illum? Hic aspernatur magnam, exercitationem nam assumenda veritatis dolor omnis sint nulla quidem saepe.
                  </p>
                  <div className="additional-images row g-5">
                    <img className="col-6" src="https://fakeimg.pl/600x400?text=Product+Image+2&font=bebas" alt="placeholder" />
                    <img className="col-6" src="https://fakeimg.pl/600x400?text=Product+Image+3&font=bebas" alt="placeholder" />
                  </div>
                  <p className="mt-5">
                    <strong>How is it different from competitors?</strong><br></br>
                    Minimum cost product with everything OpenSource. You can build one on your own. We are just providing a ready to use product.<br></br> Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio odit error a, in consectetur eos amet rem ipsa sit porro itaque esse eveniet delectus, expedita pariatur velit similique iure soluta.
                  </p>
                  <p>
                    To learn more request a demo today!
                  </p>
                  <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us</Link>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="eduvibe-sidebar">
                  <div className="eduvibe-widget eduvibe-widget-details">
                    <h5 className="title">ABOUT M2M Product</h5>
                    <div className="widget-content">
                      <ul>
                        {/* <li><span><i className="icon-Campus"></i> { eventItem.company }</span></li> */}
                        <li><span><i className="icon-share-fill"></i> <a href={eventItem.location}>{ eventItem.company }</a></span></li>
                        <li><span><i className="icon-presentation"></i> { eventItem.category }</span></li>
                        {/* <li><span><i className="icon-map-pin-line"></i> {company ? <a href={eventItem.location}>Website</a> : eventItem.location}</span></li> */}
                        <li>
                        Our mission is to equip individuals with in-demand STEM skills, foster Research and Development capabilities, and forge connections, opportunities and partnerships with leading businesses in Emerging Technologies such as Data Science, Machine Learning, Artificial Intelligence, and Virtual/Augmented Reality. These skills and research experiences are highly sought after in diverse sectors including CleanTech, Advanced Manufacturing, Business Intelligence, Finance, and Healthcare.
                        </li>
                      </ul>
                      {/* {!company && <div className="read-more-btn mt--45">
                        <a className="edu-btn btn-secondary text-center" href="https://form.jotform.com/m2mtech/wil-programs-sign-up">Register Now<i className="icon-arrow-right-line-right"></i></a>
                      </div>} */}
                      <Link className="edu-btn btn-secondary" to="/contact-us">Contact Us</Link>
                      {/* <div className="read-more-btn mt--30 text-center">
                        <div className="eduvibe-post-share">
                          <span>Share: </span>
                          <a className="linkedin" href="/"><i className="icon-linkedin"></i></a>
                          <a className="facebook" href="/"><i className="icon-Fb"></i></a>
                          <a className="twitter" href="/"><i className="icon-Twitter"></i></a>
                          <a className="youtube" href="/"><i className="icon-youtube"></i></a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default M2MProduct;