import React, { useEffect, useRef, useState } from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import FooterOne from '../../common/footer/FooterOne';
import BABanner2 from '../../components/business-accelerator2/BABanner2';
import BAAbout2 from '../../components/business-accelerator2/BAAbout2';
import BASupport from '../../components/business-accelerator2/BASupport';
import BAProgramOverview from '../../components/business-accelerator2/BAProgramOverview';
import { scrollIntoViewWithOffset } from '../../utils/scrollToViewWithOffset';
import BAPONavbar from '../../components/business-accelerator2/BAPONavbar';
import BABenefits from '../../components/business-accelerator2/BABenefits';
import BAComprehensive from '../../components/business-accelerator2/BAComprehensive';
import BACPONavbar from '../../components/business-accelerator2/BACPONavbar';
import BAApplicationProcess from '../../components/business-accelerator2/BAApplicationProcess';
import BAPerfectFor from '../../components/business-accelerator2/BAPerfectFor';
import BAFooter from '../../components/business-accelerator2/BAFooter';
import LogoTwo from '../../components/logos/LogoTwo';

const BusinessAccelerator2 = () => {
	const [isSticky, setIsSticky] = useState(false);
  const [showTalentPipeline, setShowTalentPipeline] = useState(true);
  const [showMentorshipCoaching, setShowMentorshipCoaching] = useState(false);
  const [outerBAEntry, setOuterBAEntry] = useState(false);
  const [innerBAEntry, setInnerBAEntry] = useState(false);
  const [activeBAPOTab, setActiveBAPOTab] = useState("TalentPipeline");
  const [showBusinessCoachingAdvisory, setShowBusinessCoachingAdvisory] = useState(true);
  const [showFundingResources, setShowFundingResources] = useState(false);
  const [outerBACEntry, setOuterBACEntry] = useState(false);
  const [innerBACEntry, setInnerBACEntry] = useState(false);
  const [activeBACPOTab, setActiveBACPOTab] = useState("BusinessCoachingAdvisory");

  const targetBASectionRef = useRef(null);
  const targetBASectionInnerRef = useRef(null);
  
  const handleTalentPipelineClick = () => {
    document.getElementById("TalentPipeline").classList.add("active-program-overview");
    document.getElementById("MentorshipCoaching").classList.remove("active-program-overview");
    document.getElementById("TalentPipeline")?.classList.add("active-program-overview");
    document.getElementById("MentorshipCoaching")?.classList.remove("active-program-overview");
    setShowTalentPipeline(true);
    setShowMentorshipCoaching(false);
    setActiveBAPOTab("TalentPipeline");
  }
  
  const handleMentorshipCoachingClick = () => {
    document.getElementById("TalentPipeline").classList.remove("active-program-overview");
    document.getElementById("MentorshipCoaching").classList.add("active-program-overview");
    document.getElementById("TalentPipeline")?.classList.remove("active-program-overview");
    document.getElementById("MentorshipCoaching")?.classList.add("active-program-overview");
    setShowTalentPipeline(false);
    setShowMentorshipCoaching(true);
    setActiveBAPOTab("MentorshipCoaching");
  }

  const targetBACSectionRef = useRef(null);
  const targetBACSectionInnerRef = useRef(null);
  
  const handleBusinessCoachingAdvisoryClick = () => {
    document.getElementById("BusinessCoachingAdvisory").classList.add("active-program-overview");
    document.getElementById("FundingResources").classList.remove("active-program-overview");
    document.getElementById("BusinessCoachingAdvisory")?.classList.add("active-program-overview");
    document.getElementById("FundingResources")?.classList.remove("active-program-overview");
    setShowBusinessCoachingAdvisory(true);
    setShowFundingResources(false);
    setActiveBACPOTab("BusinessCoachingAdvisory");
  }
  
  const handleFundingResourcesClick = () => {
    document.getElementById("BusinessCoachingAdvisory").classList.remove("active-program-overview");
    document.getElementById("FundingResources").classList.add("active-program-overview");
    document.getElementById("BusinessCoachingAdvisory")?.classList.remove("active-program-overview");
    document.getElementById("FundingResources")?.classList.add("active-program-overview");
    setShowBusinessCoachingAdvisory(false);
    setShowFundingResources(true);
    setActiveBACPOTab("FundingResources");
  }
  
  useEffect(() => {
    if(outerBAEntry) {
      if(!innerBAEntry) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    } else {
      setIsSticky(false);
    }
  }, [outerBAEntry, innerBAEntry])
  
  useEffect(() => {
    if(outerBACEntry) {
      if(!innerBACEntry) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    } else {
      setIsSticky(false);
    }
  }, [outerBACEntry, innerBACEntry])
  
  useEffect(() => {
    const anchor = window.location.hash.split('#')[1];
    if(anchor) {
      scrollIntoViewWithOffset(anchor);
    }

    let targetInnerCurrent = targetBASectionInnerRef.current;
    let targetCurrent = targetBASectionRef.current;
    
    let targetBACInnerCurrent = targetBACSectionInnerRef.current;
    let targetBACCurrent = targetBACSectionRef.current;
    
    const innerObserver = new IntersectionObserver(
      ([innerEntry]) => {
        if(innerEntry.isIntersecting) {
          setInnerBAEntry(true);
        } else {
          setInnerBAEntry(false);
        }
      },
      { rootMargin: "-135px 0px 0px 0px", threshold: 0.1 }
    );
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        if(entry.isIntersecting) {
          setOuterBAEntry(true);
        } else {
          setOuterBAEntry(false);
        }
      },
      { threshold: 0.1 }
    );
    
    if (targetInnerCurrent) {
      innerObserver.observe(targetInnerCurrent);
    }

    if (targetCurrent) {
      observer.observe(targetCurrent);
    }
    
    const innerBACObserver = new IntersectionObserver(
      ([innerEntry]) => {
        if(innerEntry.isIntersecting) {
          setInnerBAEntry(true);
        } else {
          setInnerBAEntry(false);
        }
      },
      { rootMargin: "-135px 0px 0px 0px", threshold: 0.1 }
    );
    
    const BACObserver = new IntersectionObserver(
      ([entry]) => {
        if(entry.isIntersecting) {
          setOuterBAEntry(true);
        } else {
          setOuterBAEntry(false);
        }
      },
      { threshold: 0.1 }
    );
    
    if (targetBACInnerCurrent) {
      innerBACObserver.observe(targetBACInnerCurrent);
    }

    if (targetBACCurrent) {
      BACObserver.observe(targetBACCurrent);
    }
    
    return () => {
      if (targetCurrent) {
        observer.unobserve(targetCurrent);
      }
      if (targetInnerCurrent) {
        innerObserver.unobserve(targetInnerCurrent);
      }
      
      if (targetBACCurrent) {
        innerBACObserver.unobserve(targetBACCurrent);
      }
      if (targetBACInnerCurrent) {
        BACObserver.unobserve(targetBACInnerCurrent);
      }
    };
  }, []);

	return (
		<>
			<SEO
				title="Business Accelerator"
				description="Powering Innovations with our AI solutions."
			/>
			<HeaderTwo styles="header-transparent header-style-2" searchDisable />
      <BAPONavbar
        isSticky={isSticky}
        handleTalentPipelineClick={handleTalentPipelineClick}
        handleMentorshipCoachingClick={handleMentorshipCoachingClick}
        activeBAPOTab={activeBAPOTab}
      />
      <BACPONavbar
        isSticky={isSticky}
        handleBusinessCoachingAdvisoryClick={handleBusinessCoachingAdvisoryClick}
        handleFundingResourcesClick={handleFundingResourcesClick}
        activeBACPOTab={activeBACPOTab}
      />
			<BABanner2 />
      <LogoTwo />
			<BAAbout2 />
			<BASupport />
			<BAProgramOverview
				targetRefBA={targetBASectionRef}
				innerRefBA={targetBASectionInnerRef}
				showTalentPipeline={showTalentPipeline}
				showMentorshipCoaching={showMentorshipCoaching}
				handleTalentPipelineClick={handleTalentPipelineClick}
				handleMentorshipCoachingClick={handleMentorshipCoachingClick}
			/>
      <BABenefits />
      <BAComprehensive
        targetRefBAC={targetBACSectionRef}
        innerRefBAC={targetBACSectionInnerRef}
        showBusinessCoachingAdvisory={showBusinessCoachingAdvisory}
        showFundingResources={showFundingResources}
        handleBusinessCoachingAdvisoryClick={handleBusinessCoachingAdvisoryClick}
        handleFundingResourcesClick={handleFundingResourcesClick}
      />
      <BAApplicationProcess />
      <BAPerfectFor />
      <BAFooter />
			<FooterOne />
		</>
    )
}
export default BusinessAccelerator2;