import React from 'react'
import SEO from '../../../common/SEO';
import HeaderTwo from '../../../common/header/HeaderTwo';
import FooterOne from '../../../common/footer/FooterOne';
import MembershipAIReadyBanner from '../../../components/annual-membership/membership-aiready/MembershipAIReadyBanner';
import MembershipAIReadyGlance from '../../../components/annual-membership/membership-aiready/MembershipAIReadyGlance';
import MembershipAIReadyOffering from '../../../components/annual-membership/membership-aiready/MembershipAIReadyOffering';
import MembershipAIReadyAbout from '../../../components/annual-membership/membership-aiready/MembershipAIReadyAbout';
import MembershipAIReadyWhereTo from '../../../components/annual-membership/membership-aiready/MembershipAIReadyWhereTo';
import MembershipAIReadyFaq from '../../../components/annual-membership/membership-aiready/MembershipAIReadyFaq';

const MembershipAIReady = () => {

  return (
    <>
      <SEO
				title="Membership AIReady plan"
				description="The AI Ready program advances your learning with specialized courses in deep learning, natural language processing, and data analytics. Gain real-world experience through employer-led projects and receive personalized mentorship to thrive in today's job market."
      />
      <HeaderTwo styles="header-transparent header-style-2" searchDisable />
      <MembershipAIReadyBanner />
      <MembershipAIReadyGlance />
      <MembershipAIReadyOffering />
      <MembershipAIReadyAbout />
      <MembershipAIReadyWhereTo />
      <MembershipAIReadyFaq />
      <FooterOne />
    </>
  )
}

export default MembershipAIReady;