import React from 'react'
import AMSectionTitle from '../AMSectionTitle'
import { Element } from 'react-scroll'

const MembershipSkillsboostGlance = () => {
  return (
    <>
      <Element className="edu-about-area bg-white datatalent-section-gap">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 mt-0 p-0">
              <AMSectionTitle
                classes = "text-left"
                // slogan = "Introduction"
                title = "At a glance"
              />
            </div>
          </div>
          <div className="row g-5 ms-glance-card-container">
            <div className="col-12 col-lg-4">
              <div className="ms-glance-card h-100">
                <div className="ms-glance-card-image">
                  <img src={`${process.env.PUBLIC_URL}/images/annual-membership/membership-skillsboost/glance-1.png`} alt="illustration" />
                </div>
                <h5 className="mt-5 mb-2">Unlock Certifications</h5>
                <p className="ms-glance-card-description">Learn the skills to complete industry certifications such as Microsoft - recognized by top tech companies worldwide.</p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="ms-glance-card h-100">
                <div className="ms-glance-card-image">
                  <img src={`${process.env.PUBLIC_URL}/images/annual-membership/membership-skillsboost/glance-2.png`} alt="illustration" />
                </div>
                <h5 className="mt-5 mb-2">Member-Based Community</h5>
                <p className="ms-glance-card-description">Receive continuous support and connect with peers and mentors online.</p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="ms-glance-card h-100">
                <div className="ms-glance-card-image">
                  <img src={`${process.env.PUBLIC_URL}/images/annual-membership/membership-skillsboost/glance-3.png`} alt="illustration" />
                </div>
                <h5 className="mt-5 mb-2">Technical Support</h5>
                <p className="ms-glance-card-description">Get help from our Data Science, ML, AI specialists.</p>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default MembershipSkillsboostGlance