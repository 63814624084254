import React from 'react'
import AMSectionTitle from '../AMSectionTitle'
import { Element } from 'react-scroll'

const MembershipAIReadyGlance = () => {
  return (
    <>
      <Element className="edu-about-area bg-white datatalent-section-gap">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 mt-0 p-0">
              <AMSectionTitle
                classes = "text-left"
                // slogan = "Introduction"
                title = "At a glance"
              />
            </div>
          </div>
          <div className="row g-5 ms-glance-card-container">
            <div className="col-12 col-lg-4">
              <div className="ms-glance-card h-100">
                <div className="ms-glance-card-image">
                  <img src={`${process.env.PUBLIC_URL}/images/annual-membership/membership-skillsboost/glance-1.png`} alt="illustration" />
                </div>
                <h5 className="mt-5 mb-2">Work Integrated Learning</h5>
                <p className="ms-glance-card-description">Real world employer led projects that expands your portfolio all while getting paid to do it!</p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="ms-glance-card h-100">
                <div className="ms-glance-card-image">
                  <img src={`${process.env.PUBLIC_URL}/images/annual-membership/membership-skillsboost/glance-2.png`} alt="illustration" />
                </div>
                <h5 className="mt-5 mb-2">Work Experience</h5>
                <p className="ms-glance-card-description">Exclusive job listings and portfolio building support to make sure you stand out.</p>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="ms-glance-card h-100">
                <div className="ms-glance-card-image">
                  <img src={`${process.env.PUBLIC_URL}/images/annual-membership/membership-skillsboost/glance-3.png`} alt="illustration" />
                </div>
                <h5 className="mt-5 mb-2">Paid Projects</h5>
                <p className="ms-glance-card-description">In each membership year, you will get <u>at least</u> one paid project, with a stipend equal or more than AI Ready's subscription cost.</p>
              </div>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default MembershipAIReadyGlance