import React, { useEffect, useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import PostTwo from '../../components/blogs/PostTwo';
import BlogsData from '../../BlogsData.json';
import InfiniteScroll from "react-infinite-scroll-component";

const Blogs = () => {
  const [items, setItems] = useState(BlogsData.sort((a,b) => new Date(b.date) - new Date(a.date)).slice(0, 4));
  const [compHeight, setCompHeight] = useState(950);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = () => {
    // a fake async api call like which sends
    // 4 more records in 1.5 secs
    if (items.length >= BlogsData.length) {
      setHasMore(false);
      return;
    }
    setLoading(true);
    setTimeout(() => {
      setItems(items.concat(BlogsData.slice(items.length, items.length + 4)));
      // setCompHeight(compHeight + 480);
      setLoading(false);
    }, 1500);
  };

  useEffect(() => {
    if (items.length >= BlogsData.length) {
      setHasMore(false);
    }
  }, [items])

  useEffect(() => {
    let footer = document.getElementById("Footer");
    setCompHeight((footer.getBoundingClientRect().height * 1).toFixed(2));
  }, [])

  return (
    <>
      <SEO title="Blogs" />
      <Layout id="scrollableDiv" style={{ overflow: "auto" }}>
        <BreadcrumbOne 
          title="Blogs"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="Blogs"
        />
        <div className="edu-elements-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              {/* <div className="col-lg-12" style={{height: `${compHeight}px`}}> */}
              <div className="col-lg-12">
                <InfiniteScroll
                  dataLength={items.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={loading && <h4>Loading...</h4>}
                  scrollableTarget="scrollableDiv"
                  style={{overflowX: "hidden"}}
                  scrollThreshold={`${compHeight}px`}
                >
                <div className="row g-5">
                  { 
                    // BlogsData.sort((a,b) => new Date(b.date) - new Date(a.date)).map((item) => (
                    items.map((item) => (
                    <div className="col-lg-6 col-sm-6 col-12" key={ item.id }>
                      <PostTwo data={item} />
                    </div>
                  ))}
                </div>
                </InfiniteScroll>
                {hasMore && !loading && <div className="col-lg-12 text-center mt-5"><button className="edu-btn btn-primary" onClick={() => fetchMoreData()}>Load more</button></div>}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Blogs;