import React from 'react';
import { Element } from 'react-scroll';

const MembershipAIReadyOffering = () => {
  return (
    <>
      <Element className="ms-offerings-section datatalent-section-gap">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5 align-items-center">
            <div className="col-12 m-0">
              <h4 className="ms-offerings-blockquote">“Work integrated learning projects demonstrate your practical experience with data science tools, techniques, and problem-solving.”</h4>
              <p className="ms-offerings-description mt-5">Get hands-on with real-world projects! This unique program offers exclusive opportunities to work on Work Integrated Learning projects in Data, ML, and AI. Gain practical experience, build your portfolio, and be ready for the AI wave.</p>
              <p className="ms-offerings-description mt-5">By engaging with our employer-led projects, you have proof of work and relevant experience to land a job.</p>
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default MembershipAIReadyOffering;