import React from 'react'
import SEO from '../../../common/SEO';
import HeaderTwo from '../../../common/header/HeaderTwo';
import FooterOne from '../../../common/footer/FooterOne';
import MembershipCareerLaunchBanner from '../../../components/annual-membership/membership-careerlaunch/MembershipCareerLaunchBanner';
import MembershipCareerLaunchGlance from '../../../components/annual-membership/membership-careerlaunch/MembershipCareerLaunchGlance';
import MembershipCareerLaunchOffering from '../../../components/annual-membership/membership-careerlaunch/MembershipCareerLaunchOffering';
import MembershipCareerLaunchAbout from '../../../components/annual-membership/membership-careerlaunch/MembershipCareerLaunchAbout';
import MembershipCareerLaunchWhereTo from '../../../components/annual-membership/membership-careerlaunch/MembershipCareerLaunchWhereTo';
import MembershipCareerLaunchFaq from '../../../components/annual-membership/membership-careerlaunch/MembershipCareerLaunchFaq';

const MembershipCareerLaunch = () => {

  return (
    <>
      <SEO
				title="Membership CareerLaunch plan"
				description="Take control of your future with CareerLaunch. Through guaranteed internships, personalized coaching, and exclusive networking opportunities, you'll gain the experience, skills, and connections to accelerate your career to the next level."
      />
      <HeaderTwo styles="header-transparent header-style-2" searchDisable />
      <MembershipCareerLaunchBanner />
      <MembershipCareerLaunchGlance />
      <MembershipCareerLaunchOffering />
      <MembershipCareerLaunchAbout />
      <MembershipCareerLaunchWhereTo />
      <MembershipCareerLaunchFaq />
      <FooterOne />
    </>
  )
}

export default MembershipCareerLaunch;