import React, { useState, useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { Element } from 'react-scroll';
import DataTalentSectionTitle from '../DataTalentSectionTitle';
 
const faqCategories = [
    {
        id: 1,
        slug: 'general-faqs',
        label: 'General FAQs'
    },
    {
        id: 2,
        slug: 'capstone-faqs',
        label: 'Capstone FAQs'
    },
    {
        id: 3,
        slug: 'payment-faqs',
        label: 'Payment FAQs'
    },
    {
      id: 4,
      slug: 'post-datatalent-faqs',
      label: "Post DataTalent FAQs"
    }
];

// const generalFAQs = {
//     leftColumn: [
//         {
//             id: 1,
//             title: 'Am I eligible for admission?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 2,
//             title: 'How long is my personal free trial?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 3,
//             title: 'Is There Any One To One Support System?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 4,
//             title: 'Is There Any Prerequisites To Learn Python?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         }
//     ],
//     rightColumn: [
//         {
//             id: 1,
//             title: 'How to Change my Plan using PayPal?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 2,
//             title: 'How long it take to create a video course?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 3,
//             title: 'How do I redeem a coupon?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 4,
//             title: 'What does it take become an author?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         }
//     ]
// }

// const capstoneFAQs = {
//     leftColumn: [
//         {
//             id: 1,
//             title: 'Is There Any One To One Support System?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 2,
//             title: 'Do You Provide Career Guideline?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 3,
//             title: 'How do I redeem a coupon?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 4,
//             title: 'How do I get a refund?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         }
//     ],
//     rightColumn: [
//         {
//             id: 1,
//             title: 'How do I get a refund?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 2,
//             title: 'How do I redeem a coupon?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 3,
//             title: 'How to Change my Plan using PayPal?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 4,
//             title: 'Is There Any Prerequisites To Learn Python?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         }
//     ]
// }

// const paymentFAQs = {
//     leftColumn: [
//         {
//             id: 1,
//             title: 'Is There Any Prerequisites To Learn Python?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 2,
//             title: 'How do I redeem a coupon?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 3,
//             title: 'How to Change my Plan using PayPal?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 4,
//             title: 'How do I get a refund?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         }
//     ],
//     rightColumn: [
//         {
//             id: 1,
//             title: 'Do You Provide Career Guideline?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 2,
//             title: 'How long it take to create a video course?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 3,
//             title: 'Am I eligible for admission?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 4,
//             title: 'How long is my personal free trial?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         }
//     ]
// }

// const postDataTalentFAQs = {
//     leftColumn: [
//         {
//             id: 1,
//             title: 'Is There Any Prerequisites To Learn Python?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 2,
//             title: 'How do I redeem a coupon?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 3,
//             title: 'How to Change my Plan using PayPal?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 4,
//             title: 'How do I get a refund?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         }
//     ],
//     rightColumn: [
//         {
//             id: 1,
//             title: 'Do You Provide Career Guideline?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 2,
//             title: 'How long it take to create a video course?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 3,
//             title: 'Am I eligible for admission?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         },
//         {
//             id: 4,
//             title: 'How long is my personal free trial?',
//             body: 'Learning management system, combines a wide range of features to present a class setting without having the students come into a physical classroom. It all depends on the WordPress plugin you go with, but in general.'
//         }
//     ]
// }
        
const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext( AccordionContext );
    const decoratedOnClick = useAccordionButton( eventKey );
    const isCurrentEventKey = activeEventKey === eventKey;
    return <button type="button" onClick={ decoratedOnClick } aria-expanded={ isCurrentEventKey ? true : false } className="edu-accordion-button">{ children }</button>
}

const DataTalentFaq = ( { wrapperClass } ) => {
    const [selectedItem, setSelectedItem] = useState( 'general-faqs' );
    const [activeId1, setActiveId1] = useState( '0' );
    const [activeId2, setActiveId2] = useState( '0' );
    const [activeId3, setActiveId3] = useState( '0' );
    const [activeId4, setActiveId4] = useState( '0' );
    // const [activeId5, setActiveId5] = useState( '0' );
    // const [activeId6, setActiveId6] = useState( '0' );
    // const [activeId7, setActiveId7] = useState( '0' );
    // const [activeId8, setActiveId8] = useState( '0' );

    function toggleActive1( id ) {
        if (activeId1 === id) {
            setActiveId1(null);
        } else {
            setActiveId1(id);
        }
    }
    function toggleActive2( id ) {
        if (activeId2 === id) {
            setActiveId2(null);
        } else {
            setActiveId2(id);
        }
    }
    function toggleActive3( id ) {
        if (activeId3 === id) {
            setActiveId3(null);
        } else {
            setActiveId3(id);
        }
    }
    function toggleActive4( id ) {
        if (activeId4 === id) {
            setActiveId4(null);
        } else {
            setActiveId4(id);
        }
    }
    // function toggleActive5( id ) {
    //     if (activeId5 === id) {
    //         setActiveId5(null);
    //     } else {
    //         setActiveId5(id);
    //     }
    // }
    // function toggleActive6( id ) {
    //     if (activeId6 === id) {
    //         setActiveId6(null);
    //     } else {
    //         setActiveId6(id);
    //     }
    // }
    // function toggleActive7( id ) {
    //     if (activeId7 === id) {
    //         setActiveId7(null);
    //     } else {
    //         setActiveId7(id);
    //     }
    // }
    // function toggleActive8( id ) {
    //     if (activeId8 === id) {
    //         setActiveId8(null);
    //     } else {
    //         setActiveId8(id);
    //     }
    // }

  return (
    <>
      <Element className={ `edu-accordion-area accordion-shape-1 datatalent-section-gap datatalent-faq-section ${ wrapperClass ? wrapperClass : '' } ` }>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
                <DataTalentSectionTitle
                  classes = "text-center"
                  slogan = "General Answers"
                  title = "Frequently Asked Questions"
                />
              </div>
          </div>

          <div className="row">
              <div className="col-lg-12">
                  <ul className="edu-tab-nav nav nav-tabs mt--40">
                      {
                          faqCategories.map( ( button ) => (
                              <li className="nav-item" key={ button.id }>
                                  <button
                                      className={ button.slug === selectedItem ? 'nav-link active' : 'nav-link'}
                                      type="button"
                                      aria-label={button.label}
                                      onClick={ () => setSelectedItem( button.slug ) }
                                  >
                                      { button.label }
                                  </button>
                              </li>
                          ) )
                      }
                  </ul>
              </div>
          </div>
          { selectedItem === 'general-faqs' && 
            <div className="row g-5 align-items-center mt--20">
              <div className="datatalent-faq-accordion-container col-12">
                <div className="accordion-style-1">
                  <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                    <Accordion.Item eventKey={1} onClick={() => toggleActive1("1")} bsPrefix={`edu-accordion-item ${activeId1 === "1" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={1}>Are Microsoft certifications mandatory?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          Obtaining Microsoft certifications are not mandatory for your participation in our program, but we highly encourage you to consider them after completing DataTalent! These certifications are an excellent way to deepen your understanding of the material, boost your employability, and enhance your resume. To support your professional growth, we offer reimbursement of up to $50 CAD towards Microsoft certification resources once you pass the exam.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={8} onClick={() => toggleActive1("8")} bsPrefix={`edu-accordion-item ${activeId1 === "8" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={8}>How much of a background in data do you need to participate? Is there a baseline?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          We recommend entering the DataTalent Program with a foundational knowledge of technical skills. Particularly, knowledge of at least one programming language is strongly recommended. After we screen your application for eligibility, we will send you a technical evaluation. This 10-15 minute assessment will help confirm that you have the technical skills needed to succeed in the program.
                        </p>
                        <p>
                          If you are a beginner to tech, check out our <a href="https://m2mtechconnect.com/programs/skillsboost">SkillsBoost Program</a>, which will help you build the foundational skills needed to qualify for DataTalent! You can reach out to us at <a href="mailto:programs@m2mtechconnect.com">programs@m2mtechconnect.com</a> for more information on how you can transition from SkillsBoost into the DataTalent Program.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={11} onClick={() => toggleActive1("11")} bsPrefix={`edu-accordion-item ${activeId1 === "11" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={11}>How long does the program run for, and how often do new cohorts start?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          DataTalent has a cohort-based structure, where you will be with a group of peers starting at the same time as you. The program runs for 16 weeks in total, and we have new cohorts starting approximately once a month. When you apply, we will automatically onboard you into the cohort that aligns with your time of application!
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={9} onClick={() => toggleActive1("9")} bsPrefix={`edu-accordion-item ${activeId1 === "9" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={9}>How can I join a WIL Employer-led project?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          We require that you submit Capstone Project #1 before entering an employer-led WIL project. Once you have submitted your project, one of our team members will reach out to you via DM with instructions on how to choose a project and get started!
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={5} onClick={() => toggleActive1("5")} bsPrefix={`edu-accordion-item ${activeId1 === "5" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={5}>How much of our work do we need to record in the work log?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          Please log <em><strong>all</strong></em> the hours of work dedicated to program activities each week (completing courses, project brainstorming, etc). Submitting this work log is mandatory, as it helps us track your progress, assess engagement, and identify if any additional support may be needed to keep you on track.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          }

          { selectedItem === 'capstone-faqs' && 
            <div className="row g-5 align-items-center mt--20">
                <div className="datatalent-faq-accordion-container col-12">
                    <div className="accordion-style-1">
                        <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                            <Accordion.Item eventKey={2} onClick={() => toggleActive2("2")} bsPrefix={`edu-accordion-item ${activeId1 === "2" ? 'active-item' : ''}`}>
                              <div className="edu-accordion-header">
                                <CustomToggle eventKey={2}>Should we complete all of the course bundles first, before starting the Capstone projects?</CustomToggle>
                              </div>
                              <Accordion.Body bsPrefix="edu-accordion-body">
                                <p>
                                  Please feel free to work through the course bundles simultaneously with your capstone projects if that is supportive to your learning.
                                </p>
                                <p className="mb-0">
                                  We recommend focusing each Capstone on a key area from the mandatory bundles— <a href="https://courses.m2mtechconnect.com/bundles/data-science-group-courses">Data Analysis</a>, <a href="https://courses.m2mtechconnect.com/bundles/advanced-visualizations-intro-to-ml">Data Visualization</a>, and <a href="https://courses.m2mtechconnect.com/bundles/no-code-low-code-visual-ml">No-Code Visual Machine Learning</a>. For example:
                                </p>
                                <ul>
                                  <li><strong>Capstone Project 1</strong> on Data Analysis and Manipulation</li>
                                  <li><strong>Capstone Project 2</strong> on Data Visualization</li>
                                  <li><strong>Capstone Project 3</strong> on Visual Machine Learning</li>
                                </ul>
                                <p>
                                  This approach would allow you to build a well-rounded skill set that will be valuable for the Employer-led Work Integrated Learning Project. However, you also have flexibility in choosing topics for your Capstones, so feel free to focus on areas that interest you!<br></br>
                                </p>
                                <p>
                                  A full program timeline with additional course topics is also available in the member guide. While Supervised Machine Learning, Unsupervised Learning, Reinforcement Learning, and Neural Networks are optional, they are included in the course bundles for those interested in expanding their skills and enhancing their learning further.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={3} onClick={() => toggleActive2("3")} bsPrefix={`edu-accordion-item ${activeId1 === "3" ? 'active-item' : ''}`}>
                              <div className="edu-accordion-header">
                                <CustomToggle eventKey={3}>Do we have to complete all three Capstones?</CustomToggle>
                              </div>
                              <Accordion.Body bsPrefix="edu-accordion-body">
                                <p>
                                  We highly recommend completing all three capstone projects, as this will strengthen your portfolio and your employability. However, at a minimum, you must complete Capstone Project #1 and then the employer-led WIL project.
                                </p>
                                <p>
                                  There are no hard deadlines for each capstone—we just require you to complete Capstone Project #1 as soon as possible before the 8-week mark so that you are prepared and able to get started on the Employer-led project!
                                </p>
                                <p>
                                  However, it is important to note that once you submit the first Capstone, you may get started on the WIL Employer-led project earlier if you wish. Then you may continue working on the remaining capstones while working on the WIL at the same time!
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={4} onClick={() => toggleActive2("4")} bsPrefix={`edu-accordion-item ${activeId1 === "4" ? 'active-item' : ''}`}>
                              <div className="edu-accordion-header">
                                <CustomToggle eventKey={4}>Can we combine Capstone 1 and Capstone 2 to save some time?</CustomToggle>
                              </div>
                              <Accordion.Body bsPrefix="edu-accordion-body">
                                <p>
                                  If you're finding it challenging to balance the program with work or other commitments, you may combine Capstone 1 and Capstone 2 if necessary. In this case, you would develop a single project that incorporates both the objectives of Capstone 1 and Capstone 2. For instance, you might create a project involving both Data Analysis and Data Visualization, and then divide your presentation into two separate recordings. The first video would focus on the data analysis aspect and serve as your submission for Capstone 1, while the second video would cover data visualization and be submitted for Capstone 2. If you do choose this route, please ensure that you submit the videos separately and clearly specify which portion corresponds to Capstone 1 and which to Capstone 2.
                                </p>
                                <p className="mb-0">
                                  Although we understand that time constraints and multiple commitments can make the combined approach more manageable, we still strongly recommend completing each Capstone individually, based on the specific course bundles, such as:
                                </p>
                                <ul>
                                  <li><strong>Capstone Project 1</strong> on Data Analysis and Manipulation</li>
                                  <li><strong>Capstone Project 2</strong> on Data Visualization</li>
                                  <li><strong>Capstone Project 3</strong> on Visual Machine Learning</li>
                                </ul>
                                <p>
                                  This approach is optimal because it ensures you develop a comprehensive skill set, which will better prepare you for the final employer project. Completing each capstone independently allows you to build skills sequentially and in depth, which is valuable for reinforcing learning and meeting the expectations of employers!
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={7} onClick={() => toggleActive2("7")} bsPrefix={`edu-accordion-item ${activeId1 === "7" ? 'active-item' : ''}`}>
                              <div className="edu-accordion-header">
                                <CustomToggle eventKey={7}>What if we are not comfortable with our project videos being shared on social media?</CustomToggle>
                              </div>
                              <Accordion.Body bsPrefix="edu-accordion-body">
                                <p>
                                  If you would prefer not to have your project videos shared on social media, that's absolutely fine! Just let us know, and we will ensure your work remains private.
                                </p>
                              </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </div>
          }

          { selectedItem === 'payment-faqs' && 
            <div className="row g-5 align-items-center mt--20">
              <div className="datatalent-faq-accordion-container col-12">
                <div className="accordion-style-1">
                  <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                    <Accordion.Item eventKey={10} onClick={() => toggleActive3("10")} bsPrefix={`edu-accordion-item ${activeId1 === "10" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={10}>How does the payment structure of the DataTalent Program work?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          You'll pay a $500 deposit upfront to purchase the course bundle and then after successfully completing the program you'll receive a $1000 stipend (giving you a net gain of $500).
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={13} onClick={() => toggleActive3("13")} bsPrefix={`edu-accordion-item ${activeId1 === "13" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={13}>How does purchasing the bundles work? On the website, the course bundles are listed as $700 each, but the program says that we are purchasing them for $500.</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          Purchasing instructions will be sent to you via Slack Direct Message during onboarding. DataTalent Members are provided with a special program coupon code that reduces the cost of the course bundle to $500 CAD.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          }
          
          { selectedItem === 'post-datatalent-faqs' && 
            <div className="row g-5 align-items-center mt--20">
              <div className="datatalent-faq-accordion-container col-12">
                <div className="accordion-style-1">
                  <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                    <Accordion.Item eventKey={12} onClick={() => toggleActive4("12")} bsPrefix={`edu-accordion-item ${activeId1 === "12" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={12}>Are we still able to access course study material after we graduate from the program?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          You will have access to the course bundles for 6 months from your date of purchase! But you can definitely take notes/download the notebooks and such to refer back to later before it expires.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={6} onClick={() => toggleActive4("6")} bsPrefix={`edu-accordion-item ${activeId1 === "6" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={6}>From previous cohorts, how many participants were employed following the program?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          Our ultimate goal is to help all of our program participants land a job placement. Our post-program employment rate is currently at over 70% and we are striving to provide all our participants with career-related resources like networking opportunities, live career workshops and personalized job-search support!
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          }
        </div>
      </Element>
    </>
  )
}

export default DataTalentFaq;