import React, { useState, useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { Element } from 'react-scroll';
import AMSectionTitle from '../AMSectionTitle';
        
const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext( AccordionContext );
  const decoratedOnClick = useAccordionButton( eventKey );
  const isCurrentEventKey = activeEventKey === eventKey;
  return <button type="button" onClick={ decoratedOnClick } aria-expanded={ isCurrentEventKey ? true : false } className="edu-accordion-button">{ children }</button>
}

const MembershipAIReadyFaq = ( { wrapperClass } ) => {
  const [activeId1, setActiveId1] = useState( '0' );

  function toggleActive1( id ) {
    if (activeId1 === id) {
      setActiveId1(null);
    } else {
      setActiveId1(id);
    }
  }

  return (
    <>
      <Element>
        <div className={ `edu-accordion-area accordion-shape-1 datatalent-section-gap datatalent-faq-section ${ wrapperClass ? wrapperClass : '' } ` }>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <AMSectionTitle
                  classes = "text-center"
                  slogan = "General Answers"
                  title = "Frequently Asked Questions"
                />
              </div>
            </div>
            <div className="row g-5 align-items-center mt--20">
              <div className="datatalent-faq-accordion-container col-12">
                <div className="accordion-style-1">
                  <Accordion bsPrefix="edu-accordion" defaultActiveKey="0" flush>
                    <Accordion.Item eventKey={1} onClick={() => toggleActive1("1")} bsPrefix={`edu-accordion-item ${activeId1 === "1" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={1}>Is AIReady right for me?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          AIReady is perfect for those who want to start using what they learned in a safe setting that promotes learning and growth. WIL helps expand your portfolio too in order to get your first internships in tech.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={2} onClick={() => toggleActive1("2")} bsPrefix={`edu-accordion-item ${activeId1 === "2" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={2}>What makes AIReady different from other data programs?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          AIReady includes a paid WIL (work-integrated learning) project where you get to apply everything you learned and get a chance to showcase your skills. 
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={3} onClick={() => toggleActive1("3")} bsPrefix={`edu-accordion-item ${activeId1 === "3" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={3}>Why are WILs helpful?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          Work-integrated learning (WIL) models are beneficial because it not only furthers your learning on the job but it broadens your views in data science setting you up for success in the workplace. Plus it's paid!
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={4} onClick={() => toggleActive1("4")} bsPrefix={`edu-accordion-item ${activeId1 === "4" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={4}>How are courses delivered?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          AIReady has all the same courses as Skillsboost and more advanced courses in deep learning and NLP, all online all self paced.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={5} onClick={() => toggleActive1("5")} bsPrefix={`edu-accordion-item ${activeId1 === "5" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={5}>How long do I have access for?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          You will have access for 365 days from when you purchase the AIReady program.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={6} onClick={() => toggleActive1("6")} bsPrefix={`edu-accordion-item ${activeId1 === "6" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={6}>I'm looking for something even more hands on than WILs.</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          WILs are a great starting point to start getting experience but if you want something more in depth we recommend you checking out AIReady that includes a guaranteed data science internship.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey={7} onClick={() => toggleActive1("7")} bsPrefix={`edu-accordion-item ${activeId1 === "7" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={7}>What if we are not comfortable with our project videos being shared on social media?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          We would recommend you checking out our AI Ready program that lets you work on paid data science projects to gain real-world experience or Career Launch program that includes a guaranteed internship. 
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={8} onClick={() => toggleActive1("8")} bsPrefix={`edu-accordion-item ${activeId1 === "8" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={8}>How much of a background in data do you need to participate? Is there a baseline?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          DataTalent welcomes participants from various backgrounds! While some have prior experience in data-related fields like IT or Analytics, we also have participants from areas such as Marketing and Environmental Science. Ideally, having some familiarity with a programming language is helpful, but we are prepared to support you regardless of your starting point.
                        </p>
                        <p>
                        If you are aiming for a more technical role in Data Analytics, prior exposure to technical skills would be beneficial. However, for a focus on a less technical-heavy field like Business Analytics, there is ample room for skill-building within the program. Additionally, we provide strong technical support to help you throughout your training, with guidance available from our dedicated technical team to assist with concepts you don't understand or provide inspiration for your projects.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={9} onClick={() => toggleActive1("9")} bsPrefix={`edu-accordion-item ${activeId1 === "9" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={9}>How can I join a WIL Employer-led project?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          Once you submit your first Capstone project, you will be granted access to the cohort's Employer-led Projects channel on Slack! In this channel, you can view all ongoing Work Integrated Learning projects and choose one that aligns with your interests. Please keep in mind that projects are posted regularly on an ongoing basis, even after you join one.
                        </p>
                        <p>
                          When you find a project that piques your interest, simply post a message in the channel to express your enthusiasm or ask any questions! We will connect you with the project lead and you may get started right away. Joining an employer-led project is a valuable experience, so we encourage you to submit Capstone #1 as soon as possible, stay engaged in the channel, and explore any opportunities that align with your career goals! Then you may continue to work on Capstones 2 and 3 while engaging in the WIL project at the same time.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={10} onClick={() => toggleActive1("10")} bsPrefix={`edu-accordion-item ${activeId1 === "10" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={10}>How does the payment structure of the DataTalent Program work?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          You'll pay a $500 deposit upfront to purchase the course bundle and then after successfully completing the program you'll receive a $1000 stipend (giving you a net gain of $500).
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={11} onClick={() => toggleActive1("11")} bsPrefix={`edu-accordion-item ${activeId1 === "11" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={11}>How long does the program run for, and how often do new cohorts start?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          DataTalent has a cohort-based structure, where you will be with a group of peers starting at the same time as you. The program runs for 16 weeks in total, and we have new cohorts starting approximately once a month. When you apply, we will automatically onboard you into the cohort that aligns with your time of application!
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={12} onClick={() => toggleActive1("12")} bsPrefix={`edu-accordion-item ${activeId1 === "12" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={12}>Are we still able to access course study material after we graduate from the program?</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          You will have access to the course bundles for 6 months from your date of purchase! But you can definitely take notes/download the notebooks and such to refer back to later before it expires.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={13} onClick={() => toggleActive1("13")} bsPrefix={`edu-accordion-item ${activeId1 === "13" ? 'active-item' : ''}`}>
                      <div className="edu-accordion-header">
                        <CustomToggle eventKey={13}>How does purchasing the bundles work? On the website, the course bundles are listed as $700 each, but the program says that we are purchasing them for $500.</CustomToggle>
                      </div>
                      <Accordion.Body bsPrefix="edu-accordion-body">
                        <p>
                          Purchasing instructions will be sent to you via Slack Direct Message during onboarding. DataTalent Members are provided with a special program coupon code that reduces the cost of the course bundle to $500 CAD.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item> */}
                  </Accordion>
                </div>
              </div>                
            </div>
          </div>
        </div>
      </Element>
    </>
  )
}

export default MembershipAIReadyFaq;